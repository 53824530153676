<template>
    <div>
        <div id="main">
            <div id="head">
                <div id="content">
                    <div id="logo">
                        <img src="../assets/logo.png" />
                    </div>
                </div>
            </div>
            <div id="corps">
                <div id="alerts">
                    <div id="alert" v-if="error.value">{{ error.msg }}</div>
                </div>
                <form @submit="connect" v-if="!loading" id="form">
                    <div class="lab">
                        <label>ID Entretien</label>
                    </div>
                    <div class="inpu">
                        <input type="text" v-model="credentials.id" required>
                    </div>
                    <div class="lab">
                        <label>Mot de passe Entretien</label>
                    </div>
                    <div class="inpu">
                        <input type="password" v-model="credentials.mdp" required>
                    </div>
                    <br>
                    <div>
                        <button type="submit">Connection</button>
                    </div>
                </form>
                <div id="loader" v-if="loading">
                    <img src="../assets/728.gif" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Login',
    data() {
        return {
            credentials: {
                matricule: '',
                entite: '',
                dateNaissance: ''
            },
            loading: false,
            error: {
                value: false,
                msg: 'Vos identifiants sont incorrects'
            },
        }
    },
    methods: {
        connect: function (e) {
            e.preventDefault();
            this.loading = true;
            this.error.value = false;
            let params = {
                id: this.credentials.id,
                mdp: this.credentials.mdp,
            }
            axios.post('main.php', params).then(
                response => {
                    if (response.data == false || response.data == "-1") {
                        this.error.value = true;
                        if (response.data == false)
                            this.error.msg = "Vos identifiants sont incorrects";
                        else
                            this.error.msg = "Votre entretien a été déjà saisi et envoyé à votre responsable. Si vous souhaitez y apporter des modifications nous vous invitons à contacter votre responsable";
                        this.loading = false;
                    } else {
                        this.$store.commit('setCollab', response.data);
                        this.$router.push('entretien');
                    }
                }).catch(
                    error => {
                        console.log(error);
                        this.error.value = true;
                        this.error.msg = error;
                        this.loading = false;
                    }
                )
        }
    }
}
</script>

<style scoped>
#alert {
    padding: 20px 20px;
    border: 1px solid transparent;
    border-radius: .25rem;
    border-color: #feb6c0;
    background-color: #ffcfe7;
    margin-top: 50px;
}

.lab {
    font-size: 16px;
    font-weight: 600;
}

.inpu {
    margin-top: 10px;
    margin-bottom: 15px;
}

.inpu input {
    height: 30px;
    font-size: 16px;
    padding-left: 15 px;
}

.inpu select {
    height: 35px;
}

.inpu:focus {
    -webkit-box-shadow: inset 0px 0px 0px 2px #ff9015;
    -moz-box-shadow: inset 0px 0px 0px 2px #ff9015;
    box-shadow: inset 0px 0px 0px 2px #ff9015;
    outline: none !important;
    border-color: #ff9015;
}

button {
    background-color: #ff9015;
    color: #002169;
    min-width: 100px;
    padding: 7px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    border: none;
    float: right;
}

button:hover {
    background-color: #002169;
    color: white;
    border: 1px solid #ff9015;
}

#loader {
    margin-top: 30%;
    text-align: center;
}

#head {
    background-color: #000644;
}

#head #content {
    width: 60%;
    margin: auto;
}

#head #logo {
    text-align: center;
    padding: 30px 0;
    margin-top: auto;
    margin-bottom: auto;
}

#head #logo img {
    width: 300px;
}

#main {
    width: 100%;
    margin: 0;
}

#form {
    margin-top: 40px;
}

#corps {
    width: 650px;
    margin: auto;
    padding-top: auto;
    padding-bottom: auto;
    background-color: none;
    height: 100%;
}

#corps input,
#corps select {
    width: 100%;
}

@media screen and (max-width: 800px) {
    #head #content {
        width: 90%;
        min-width: 600px;
    }

    #head #logo {
        padding: 0px;
    }

    #corps {
        width: 90%;
        min-width: 600px;
        margin: auto;
        margin-top: 50px;
    }

    #form {
        margin-top: 5%;
    }
}

@media screen and (max-width: 600px) {
    #head #content {
        text-align: center;
        width: 100%;
        min-width: 320px;
    }

    #corps {
        width: 95%;
        min-width: 320px;
        margin: auto;
        margin-top: 50px;
    }
}
</style>
