<template>
<div v-if="collab != null" id="app">
    <datalist id="diplomes">
        <option value="BREVET DES COLLEGES" />
        <option value="BAC GENERAL.." />
        <option value="BAC PROFESSIONNEL.." />
        <option value="DUT .." />
        <option value="BTS .." />
        <option value="LICENCE GENERALE .." />
        <option value="LICENCE PROFESSIONNEL.." />
        <option value="MASTER .." />
    </datalist>
    <datalist id="clients">
        <option value="0-MANAGEMENT.NEUILLY.DIRECTION" />
        <option value="0-MANAGEMENT.NEUILLY.ADMINISTRATIF" />
        <option value="0-MUTUALISE.LGC.HELPDESK" />
        <option value="0-MUTUALISE.LGC.BACKOFFICE" />
        <option value="0-MUTUALISE.LGC.SUPERVISION" />
        <option value="0-MUTUALISE.MERIGNAC.SUPERVISION" />
        <option value="0-MUTUALISE.MERIGNAC.HELPDESK" />
        <option value="ACCOR.INSITU." />
        <option value="ACS.INSITU." />
        <option value="ADELANTO.INSITU." />
        <option value="AF2A.INSITU." />
        <option value="AGENCE DUVAL.INSITU." />
        <option value="AGRICA.INSITU." />
        <option value="ALLIANZ.MERIGNAC.ABS" />
        <option value="ALLIANZ.INSITU.METIER" />
        <option value="ALLIANZ.LGC.AEC" />
        <option value="ALLIANZ.LGC.HELPDESK" />
        <option value="ALLIANZ.MERIGNAC.AEC" />
        <option value="ALLIANZ.MERIGNAC.HELPDESK" />
        <option value="ALLIANZ.INSITU.PROD" />
        <option value="ALLIANZ.INSITU.N2 ABS" />
        <option value="ALLIANZ.LGC.N2 ABS" />
        <option value="ALLIANZ.MERIGNAC.N2 ABS" />
        <option value="ALLIANZ.INSITU." />
        <option value="ALLIANZ.INSITU.IT" />
        <option value="ALLIANZ.INSITU.N3 ABS" />
        <option value="ALLIANZ.LGC.ITOOL" />
        <option value="ALTEN.INSITU." />
        <option value="AMAP.INSITU." />
        <option value="APAVE.INSITU." />
        <option value="APRIA.LGC." />
        <option value="APRIA.INSITU." />
        <option value="APSIDE.INSITU.GENERALI" />
        <option value="APSIDE.INSITU." />
        <option value="ARDIAN.INSITU." />
        <option value="ARGEDIS.INSITU." />
        <option value="ARKEMA.INSITU." />
        <option value="ARTEMIS / RAMPAR.INSITU." />
        <option value="ARUM TECH.INSITU." />
        <option value="BAYARD.INSITU." />
        <option value="CAGIP.INSITU." />
        <option value="CAGIP.GRADIGNAN." />
        <option value="CLASSIC FINE FOOD.INSITU." />
        <option value="CLIENT.DEV.TEST.MERIGNAC.DIRECTION" />
        <option value="CLUB SENIOR.INSITU." />
        <option value="CONSEIL D ETAT.INSITU." />
        <option value="CONSEIL D ETAT.MERIGNAC." />
        <option value="CONSEIL D ETAT.LGC." />
        <option value="CONTRATS SUSPENDUS.INSITU." />
        <option value="COSTA CROSIERE.INSITU." />
        <option value="DE MUNICH.INSITU." />
        <option value="DELOITTE.INSITU." />
        <option value="DONALSON.INSITU." />
        <option value="ENGIE.INSITU." />
        <option value="EURONEXT.INSITU." />
        <option value="EXANE.INSITU." />
        <option value="EXANE.LGC." />
        <option value="FIGARO.INSITU." />
        <option value="FNAC - DARTY.INSITU." />
        <option value="FRANCE BILLET.INSITU." />
        <option value="GALEC.INSITU.GU" />
        <option value="GALEC.INSITU.AT" />
        <option value="GALEC.LGC." />
        <option value="GENERALI.INSITU." />
        <option value="GIP-CDG.LGC." />
        <option value="GIVENCHY.INSITU." />
        <option value="GMF ASSURANCE.LGC." />
        <option value="GPSEO.INSITU." />
        <option value="HELP LINE.INSITU." />
        <option value="HLM ANTIN.INSITU." />
        <option value="IBM.INSITU.ALLIANZ" />
        <option value="INFORMADIS.INSITU." />
        <option value="INSTITUT DE FRANCE.INSITU." />
        <option value="INSTITUT SOUDURE.INSITU." />
        <option value="ISS.INSITU." />
        <option value="KRYS.INSITU." />
        <option value="LA POSTE MOBILE.INSITU." />
        <option value="LA POSTE MOBILE.INSITU.AE" />
        <option value="LA POSTE MOBILE.LGC." />
        <option value="LA POSTE MOBILE.INSITU.PROD" />
        <option value="LE BON MARCHE.INSITU." />
        <option value="LEMON GROUP.INSITU." />
        <option value="LUCERNYS.INSITU." />
        <option value="MA QUESTION MEDICALE.INSITU." />
        <option value="MAIRIE DE PARIS.INSITU." />
        <option value="MAIRIE DE PARIS.LGC." />
        <option value="MAIRIE DE PARIS.INSITU.RESEAUX" />
        <option value="MAPAD.INSITU." />
        <option value="MAPFRE.INSITU." />
        <option value="METRO.INSITU." />
        <option value="MNH.INSITU." />
        <option value="NEXTER.INSITU." />
        <option value="OCP.INSITU." />
        <option value="ODDO.INSITU." />
        <option value="OMF.INSITU." />
        <option value="OMF.LGC." />
        <option value="OMF.MERIGNAC." />
        <option value="PMU.INSITU." />
        <option value="POMPIDOU.INSITU." />
        <option value="PROGMAG.INSITU." />
        <option value="PROSERVIA.INSITU." />
        <option value="PROXISERVE.LGC." />
        <option value="QUARTUS.INSITU." />
        <option value="SIPLEC.INSITU." />
        <option value="SMI.INSITU." />
        <option value="SOCIETE GENERALE.INSITU." />
        <option value="STET.INSITU.HELPDESK" />
        <option value="STET.INSITU.SUPERVISION" />
        <option value="STET.INSITU." />
        <option value="SYNLAB.INSITU." />
        <option value="SYNLAB.LGC." />
        <option value="SYNLAB.MERIGNAC." />
        <option value="TBWA.LGC." />
        <option value="TECHNIP.INSITU." />
        <option value="UNIPREVOYANCE.INSITU." />
        <option value="VALLEE SUD.INSITU." />
        <option value="VEOLIA.INSITU." />
        <option value="VYGON.LGC." />
        <option value="WANSQUARE.LGC." />
    </datalist>
    <datalist id="postes">
        <option value="ADMINISTRATEUR SYSTEME" />
        <option value="ANALYSTE D'EXPLOITATION" />
        <option value="ASSISTANTE MANAGERIALE" />
        <option value="ASSITANTE DE GESTION" />
        <option value="CHARGE CRM" />
        <option value="CHARGE DE COMMUNICATION" />
        <option value="CHARGE DE RECHERCHE" />
        <option value="CHARGE DE RECRUTEMENT" />
        <option value="CHEF DE PROJET AMOA - MOA" />
        <option value="CHEF DE PROJET INFRA" />
        <option value="CHEF DE PROJET MOE" />
        <option value="COORDINATEUR TECHNIQUE" />
        <option value="DBA PROD" />
        <option value="DEVELOPPEUR WEB - NET" />
        <option value="DIRECTEUR DE PROJET" />
        <option value="GESTIONNAIRE DE PARC" />
        <option value="HOTESSE D'ACCUEIL" />
        <option value="INCIDENT PROLEM MANAGER" />
        <option value="INGENIEUR BIG DATA" />
        <option value="INGENIEUR DE DEVELOPPEMENT" />
        <option value="INGENIEUR DE PRODUCTION" />
        <option value="INGENIEUR DEVOPS" />
        <option value="INGENIEUR POSTE DE TRAVAIL" />
        <option value="INGENIEUR RESEAUX SECURITE" />
        <option value="INGENIEUR STOCKAGE SAN - NAS" />
        <option value="INGENIEUR SYSTEME" />
        <option value="PILOTE D'EXPLOITATION" />
        <option value="RESPONSABLE DE RECRUTEMENT" />
        <option value="RESPONSABLE OPERATIONNEL PRODUCTION" />
        <option value="RESPONSABLE QUALITÉ" />
        <option value="SERVICE DELIVERY MANAGER" />
        <option value="SUPERVISEUR" />
        <option value="TECHNICIEN HD-PROXIMITE" />
        <option value="TECHNICIEN HELPDESK N1" />
        <option value="TECHNICIEN HOTLINE APPLICATIVE" />
        <option value="TECHNICIEN SUPPORT APPLICATIF N2" />
        <option value="TECHNICIEN SUPPORT N2" />
        <option value="ASSISTANTE RH" />
        <option value="DIRECTRICE ADM & FIN" />
        <option value="RESPONSABLE COMMERICIAL" />
        <option value="GESTIONNAIRE RH" />
        <option value="GEST COMPT PAIE ADMIN" />
        <option value="CHEF DE PROJET JUNIOR" />
    </datalist>
    <datalist id="periode">
        <option value="Des que possible" />
        <option value="Dans les 6 mois" />
        <option value="Cette annee" />
        <option value="L'annee prochaine" />
    </datalist>
    <datalist id="dispositif">
        <option value="CPF" />
        <option value="A l'initiative de l'employeur" />
        <option value="A l'initiative du salarié" />
    </datalist>
    <div id="head">
        <div id="content">
            <div id="logo">
                <img width="300px" src="../assets/logo.png" />
            </div>
            <div id="title">
                <h1>Entretien professionnel obligatoire<br>
                    <span id="type">Entretien des {{ collab.typeEntretien }} ans</span>
                </h1>
            </div>
        </div>
    </div>
    <div id="loader" v-if="loading">
        <img src="../assets/728.gif" />
    </div>
    <div id="corps" v-if="!loading">

        <div id="recap">
            <div class="sub-recap">
                <h2>Entretien</h2>
                <p>Date de dernier entretien : {{ collab.dernierEntretien }}<br>
                    Type entretien : {{ collab.typeEntretien }} ans<br>
                    Date de l'entretien : {{ collab.dateTheorique }}<br>
                </p>
            </div>
            <div class="sub-recap">
                <h2>Informations personnelles</h2>
                <p>{{ collab.prenom }} {{ collab.nom }}<br>
                    Interne en {{ collab.typeContrat }}<br>
                    Matricule : {{ collab.matricule }}<br>
                    Entitée : {{ collab.entitee }}<br>
                    Date d'entrée : {{ collab.dateEntree }}<br>
                    Date de naissance : {{ collab.dateNaissance }}</p>
            </div>
            <div class="sub-recap">
                <h2>Mission actuelle</h2>
                <p>
                    Client : {{ collab.mission.client }}<br>
                    Fiche de poste : {{ collab.mission.poste }}<br>
                    Début mission : {{ collab.mission.dateDebut }}
                </p>
            </div>
        </div>
        <div id="parcours">
            <h2><span class="title-num-orange">1.</span> Votre parcours avant Vital</h2>
            <div class="content">
                <div class="sub-content">
                    <h3><span class="title-num-orange">a.</span> Formations</h3>
                    <div style="overflow-x: auto;min-height:135px;">
                        <transition name="fade2" mode="out-in">
                            <div class="empt" v-if="this.isEmptyArray(collab.parcours.etudes)">Vous n'avez renseigné aucune formation</div>
                            <table class="tables" v-if="!this.isEmptyArray(collab.parcours.etudes)">
                                <thead>
                                    <tr>
                                        <th>Année</th>
                                        <th>Intitulé du diplôme</th>
                                        <th>Niveau d'études/équivalent</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody name="fade" is="transition-group">
                                    <tr v-for="d in collab.parcours.etudes" :key="d.ID">
                                        <td>
                                            <input type="number" v-model="d.annee" min="1950" max="2200">
                                        </td>
                                        <td>
                                            <input type="search" style="text-transform: uppercase;" list="diplomes" placeholder="Intitulé du diplôme" v-model="d.diplome">
                                        </td>
                                        <td>
                                            <select v-model="d.niveau">
                                                <option hidden :selected="d.niveau === -1" value="-1">Séléctionner le niveau d'études</option>
                                                <option>BREVET</option>
                                                <option>BAC</option>
                                                <option>BAC +1</option>
                                                <option>BAC +2</option>
                                                <option>BAC +3</option>
                                                <option>BAC +4</option>
                                                <option>BAC +5</option>
                                                <option>BAC +6</option>
                                                <option>BAC +7</option>
                                                <option>BAC +8</option>
                                                <option>BAC +9</option>
                                                <option>BAC +10</option>
                                            </select>
                                        </td>
                                        <td width="50px"><span class="supp" v-on:click="removeArrayItem(collab.parcours.etudes, d.ID)">X</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </transition>
                    </div>
                    <button class="add" v-on:click="addTodipl()">Ajouter</button>
                </div>
                <div class="sub-content">
                    <h3><span class="title-num-orange">b.</span> Parcours professionnel avant votre entrée chez Vital</h3>
                    <div style="overflow-x: auto;min-height:135px">
                        <transition name="fade2" mode="out-in">
                            <div class="empt" v-if="this.isEmptyArray(collab.parcours.proPre)">Vous n'avez renseigné aucune entreprise
                                avant votre entrée chez Vital</div>
                            <table v-if="!this.isEmptyArray(collab.parcours.proPre)">
                                <thead>
                                    <tr>
                                        <th>Date de début</th>
                                        <th>Date de fin</th>
                                        <th>Poste</th>
                                        <th>Entreprise</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody name="fade" is="transition-group">
                                    <tr v-for="d in collab.parcours.proPre" :key="d.ID">
                                        <td><input type="date" v-model="d.dateDebut"></td>
                                        <td><input type="date" v-model="d.dateFin"></td>
                                        <td><input type="search" style="text-transform: uppercase;" list="postes" placeholder="Intitulé de poste" v-model="d.poste"></td>
                                        <td><input type="text" style="text-transform: uppercase;" placeholder="Nom de l'entreprise" v-model="d.entreprise"></td>
                                        <td width="50px"><span class="supp" v-on:click="removeArrayItem(collab.parcours.proPre, d.ID)">X</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </transition>
                    </div>
                    <button class="add" v-on:click="addToMissions(collab.parcours.proPre)">Ajouter</button>
                </div>
            </div>
        </div>
        <div id="formations">
            <h2><span class="title-num-orange">2.</span> Votre parcous chez Vital</h2>
            <div class="content">
                <div class="sub-content">
                    <h3><span class="title-num-orange">a.</span> Vos missions chez Vital</h3>
                    <div style="overflow-x: auto;min-height:135px">
                        <transition name="fade2" mode="out-in">
                            <div class="empt" v-if="this.isEmptyArray(collab.parcours.proPost)">Vous n'avez renseigné aucune mission chez
                                Vital</div>
                            <table v-if="!this.isEmptyArray(collab.parcours.proPost)">
                                <thead>
                                    <tr>
                                        <th>Date de début</th>
                                        <th>Date de fin</th>
                                        <th>Poste</th>
                                        <th>Client</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody name="fade" is="transition-group">
                                    <tr v-for="d in collab.parcours.proPost" :key="d.ID">
                                        <td><input type="date" v-model="d.dateDebut"></td>
                                        <td><input type="date" v-model="d.dateFin"></td>
                                        <td><input type="search" style="text-transform: uppercase;" list="postes" placeholder="Intitulé de poste" v-model="d.poste"></td>
                                        <td><input type="search" style="text-transform: uppercase;" list="clients" placeholder="Rechercher le client" v-model="d.client"></td>
                                        <td width="50px"><span class="supp" v-on:click="removeArrayItem(collab.parcours.proPost, d.ID)">X</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </transition>
                    </div>
                    <button class="add" v-on:click="addToMissions(collab.parcours.proPost)">Ajouter</button>
                </div>
                <div class="sub-content">
                    <h3><span class="title-num-orange">b.</span> Formations suivies au sein de Vital sur les 2 dernières années
                    </h3>
                    <div style="overflow-x: auto;min-height:135px">
                        <transition name="fade2" mode="out-in">
                            <div class="empt" v-if="this.isEmptyArray(collab.projet.formations2ans)">Vous n'avez renseigné aucune formation
                                sur ces deux dernières années</div>
                            <table v-if="!this.isEmptyArray(collab.projet.formations2ans)">
                                <thead>
                                    <tr>
                                        <th>Date formation</th>
                                        <th>Formation suivie</th>
                                        <th>Dîplome/certification obtenue</th>
                                        <th>Evaluation</th>
                                        <th>Origine</th>
                                        <th>Financement</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody name="fade" is="transition-group">
                                    <tr v-for="d in collab.projet.formations2ans" :key="d.ID">
                                        <td><input type="date" v-model="d.dateFormation"></td>
                                        <td><input type="text" v-model="d.libelle"></td>
                                        <td>
                                            <select v-model="d.diplome">
                                                <option value="true">Oui</option>
                                                <option value="false">Non</option>
                                            </select>
                                        </td>
                                        <td><input type="text" v-model="d.evalution"></td>
                                        <td>
                                            <select v-model="d.origine">
                                                <option value="A l'initiative de l'employeur">A l'initiative de l'employeur</option>
                                                <option value="A l'initiative du salarié">A l'initiative du salarié</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select v-model="d.financement">
                                                <option value="CPF">CPF</option>
                                                <option value="employeur">Employeur</option>
                                                <option value="auto">Autofinancé</option>
                                            </select>
                                        </td>
                                        <td width="50px"><span class="supp" v-on:click="removeArrayItem(collab.projet.formations2ans, d.ID)">X</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </transition>
                    </div>
                    <button class="add" v-on:click="addToform2()">Ajouter</button>
                </div>
            </div>
            <h2><span class="title-num-orange">3.</span> Votre projet professionnel</h2>
            <div class="content">
                <div class="sub-content">
                    <h3><span class="title-num-orange">a.</span> Vos projets d'évolution</h3>
                    <div>
                        <div class="resp">
                            <label> Souhaitez vous vous réorienter? </label>
                            <input type="radio" v-model="collab.projet.souhait.souhaitReorientation" v-bind:value="true" required>Oui
                            <input type="radio" v-model="collab.projet.souhait.souhaitReorientation" v-bind:value="false">Non
                        </div>
                    </div>
                    <div>
                        <div class="resp">
                            <label> Souhaitez vous avoir une prise de responsabilité?</label>
                            <input type="radio" v-model="collab.projet.souhait.souhaitResponsabilite" v-bind:value="true" required>Oui
                            <input type="radio" v-model="collab.projet.souhait.souhaitResponsabilite" v-bind:value="false">Non
                        </div>
                    </div>
                    <div>
                        <div class="resp">
                            <label> Souhaitez vous changer de mission/fonction?</label>
                            <input type="radio" v-model="collab.projet.souhait.souhaitChangement" v-bind:value="true" required>Oui
                            <input type="radio" v-model="collab.projet.souhait.souhaitChangement" v-bind:value="false">Non
                        </div>
                    </div>
                    <div class="projet">
                        <div class="l">
                            <label>Période souhaitée: </label>
                        </div>
                        <div class="r">
                            <input class="inpu" v-model="collab.projet.souhait.periode" type="search" list="periode">
                        </div>
                        <div class="l">
                            <label>Description : </label>
                        </div>
                        <div class="r">
                            <textarea class="inpu" v-model="collab.projet.souhait.description" />
                            </div>
            </div>
          </div>
          <div class="sub-content">
            <h3><span class="title-num-orange">b.</span> Formations souhaitées</h3>
            <div style="overflow-x: auto;min-height:135px">
              <transition name="fade2" mode="out-in">
                <div class="empt" v-if="this.isEmptyArray(collab.projet.formationsSouhait)">Vous n'avez renseigné aucun souhait
                  de formation</div>
                <table v-if="!this.isEmptyArray(collab.projet.formationsSouhait)">
                  <thead>
                    <tr>
                      <th>Période souhaitée</th>
                      <th>Intitulé de la formation</th>
                      <th>Financement</th>
                      <th>Commentaire</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody name="fade" is="transition-group">
                    <tr v-for="d in collab.projet.formationsSouhait" :key="d.ID">
                      <td><input type="search" list="periode" v-model="d.periode"></td>
                      <td><input type="text" v-model="d.formation"></td>
                      <td>
                        <select v-model="d.financement">
                          <option value="CPF">CPF</option>
                          <option value="employeur">Employeur</option>
                          <option value="auto">Autofinancé</option>
                        </select>
                      </td>
                      <td><input type="text" v-model="d.commentaire"></td>
                      <td width="50px"><span class="supp"
                          v-on:click="removeArrayItem(collab.projet.formationsSouhait, d.ID)">X</span></td>
                    </tr>
                  </tbody>
                </table>
              </transition>
            </div>
            <button class="add" v-on:click="addToform()">Ajouter</button>
          </div>
        </div>
        <h2><span v-if="collab.typeEntretien == '2'" class="title-num-orange">4.</span><span
            v-if="collab.typeEntretien == '6'" class="title-num-orange">4.</span> Mobilité géographique</h2>
        <div class="content">
          <div class="subcontent">
            <span class="help">Pour rappel le Groupe Vital dispose de deux centres de service à Paris et à
              Bordeaux</span>
            <div class="resp">
              <input type="radio" v-model="collab.mobilite.value" v-bind:value="true" required>Oui
              <input type="radio" v-model="collab.mobilite.value" v-bind:value="false">Non
            </div>
            <label>Préciser le lieu : </label>
            <div class="resp">
              <input type="text" class="inpu" v-on:change="mobiliteUpdate()" v-model="collab.mobilite.lieu"
                placeholder="Préciser le lieu" />
            </div>
            <label>Commentaire : </label>
            <div class="r">
              <textarea class="inpu" v-on:change="mobiliteUpdate()" v-model="collab.mobilite.commentaire"
                placeholder="Commentaire sur la mobilité" />
            </div>

          </div>
        </div>
        <div v-if="collab.typeEntretien == 6">
          <h2><span class="title-num-orange">5.</span> Bilan des 6 ans</h2>
          <div class="content">
            <div class="sub-content">
              <h3><span class="title-num-orange">a.</span> Date des deux derniers entretiens professionnel (tous les
                deux ans)</h3>
              <div class="quest">
                <label>Première entretien :</label>
              </div>
              <div class="resp">
                <input type="date" class="inpu" v-model="collab.bilan.entretien_1"
                  style="width:200px;padding: 5px 10px">
              </div>
              <div class="quest l">
                <label>Deuxième entretien :</label>
              </div>
              <div class="resp">
                <input type="date" class="inpu" v-model="collab.bilan.entretien_2"
                  style="width:200px;padding: 5px 10px">
              </div>
            </div>
            <div class="sub-content">
              <h3><span class="title-num-orange">b.</span> Formations/Certifications au sein de Vital sur les 6
                dernières années</h3>
              <div style="overflow-x: auto;min-height:135px">
                <transition name="fade2" mode="out-in">
                  <div class="empt" v-if="this.isEmptyArray(collab.bilan.formations6ans)">Vous n'avez renseigné aucune
                    formation/certification sur ces six dernières années</div>
                  <table v-if="!this.isEmptyArray(collab.bilan.formations6ans)">
                    <thead>
                      <tr>
                        <th>Date formation</th>
                        <th>Formation/Certification</th>
                        <th>Dîplome/certification obtenue</th>
                        <th>Evaluation</th>
                        <th>Origine</th>
                        <th>Financement</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody name="fade" is="transition-group">
                      <tr v-for="d in collab.bilan.formations6ans" :key="d.ID">
                        <td><input type="date" v-model="d.dateFormation"></td>
                        <td><input type="text" v-model="d.libelle"></td>
                        <td>
                          <select v-model="d.diplome">
                            <option value="true">Oui</option>
                            <option value="false">Non</option>
                          </select>
                        </td>
                        <td><input type="text" v-model="d.evalution"></td>
                        <td>
                          <select v-model="d.origine">
                            <option value="A l'initiative de l'employeur">A l'initiative de l'employeur</option>
                            <option value="A l'initiative du salarié">A l'initiative du salarié</option>
                          </select>
                        </td>
                        <td>
                          <select v-model="d.financement">
                            <option value="CPF">CPF</option>
                            <option value="employeur">Employeur</option>
                            <option value="auto">Autofinancé</option>
                          </select>
                        </td>
                        <td width="50px"><span class="supp"
                            v-on:click="removeArrayItem(collab.bilan.formations6ans, d.ID)">X</span></td>
                      </tr>
                    </tbody>
                  </table>
                </transition>
              </div>
              <button class="add" v-on:click="addToform6()">Ajouter</button>
            </div>
            <div class="sub-content">
              <h3><span class="title-num-orange">C.</span> Autres actions résalisés sur les 6 ans</h3>
              <div class="quest">
                <label>D'autres actions ont-elles été menées? (bilan de compétences, conseil en évolution
                  professionnel)</label>
              </div>
              <div class="resp">
                <input type="radio" v-model="collab.bilan.autresFait" v-bind:value="true">Oui
                <input type="radio" v-model="collab.bilan.autresFait" v-bind:value="false">Non
                <div style="margin-top : 5px">
                  <textarea v-if="collab.bilan.autresFait" class="inpu" v-model="collab.bilan.autresCommentaire"
                    placeholder="Précisez lequelles" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2><span v-if="collab.typeEntretien == '2'" class="title-num-orange">5.</span><span
            v-if="collab.typeEntretien == '6'" class="title-num-orange">6.</span> Commentaires</h2>
        <div class="content">
          <textarea class="inpu" v-model="collab.commentaire"
            placeholder="Commentaires pour l'entretien à venir"></textarea>
                        </div>
                        <div class="contenButtons">
                            <button v-on:click="send(false)">Enregistrer les modifications</button> <button v-on:click="send(true)">Envoyer le dossier d'entretien</button>
                        </div>
                    </div>
                </div>
            </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Entretien',
    data() {
        return {
            collab: null,
            loading: false
        }
    },
    methods: {
        mobiliteUpdate: function () {
            if (this.collab.mobilite.lieu == "" && this.collab.mobilite.commentaire == "")
                this.collab.mobilite.value = false;
            else
                this.collab.mobilite.value = true;
        },
        removeArrayItem: function (array, ID) {
            let index = 0;

            while (array[index].ID != ID)
                index++;
            array.splice(index, 1);
        },
        isEmptyArray: function (array) {
            if (array && Array.isArray(array) && array.length)
                return false
            return true
        },
        getNewItemID: function (array) {
            let tmpID = 0;
            let i = 0;

            if (!this.isEmptyArray(array)) {
                for (i = 0; i < array.length; i++) {
                    if (array[i].ID < tmpID)
                        tmpID = array[i].ID;
                }
                tmpID--;
            }
            return tmpID;
        },
        addTodipl: function () {
            let tmpDate = new Date().getFullYear();

            let formation = {
                ID: this.getNewItemID(this.collab.parcours.etudes),
                annee: tmpDate,
                niveau: -1
            }
            if (this.isEmptyArray(this.collab.parcours.etudes)) {
                this.collab.parcours.etudes = [formation]
            } else{
              formation.annee = parseInt(this.collab.parcours.etudes[this.collab.parcours.etudes.length - 1].annee) + 1;
              this.collab.parcours.etudes.push(formation);
            }
              
        },
        addToMissions: function (array) {
            array.push({
                ID: this.getNewItemID(array),
                dateDebut: new Date().toISOString().split('T')[0],
                dateFin: new Date().toISOString().split('T')[0]
            });
        },
        addToform2: function () {
            this.collab.projet.formations2ans.push({
                ID: this.getNewItemID(this.collab.projet.formations2ans),
                dateFormation: new Date().toISOString().split('T')[0]
            });
        },
        addToform6: function () {
            this.collab.bilan.formations6ans.push({
                ID: this.getNewItemID(this.collab.bilan.formations6ans),
                dateFormation: new Date().toISOString().split('T')[0]
            });
        },
        addToform: function () {
            this.collab.projet.formationsSouhait.push({
                ID: this.getNewItemID(this.collab.projet.formationsSouhait)
            });
        },
        send: function (save) {
            this.loading = true;
            axios({
                method: 'post',
                url: 'send.php',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    diplomes: this.sortedDipl(),
                    proPre: this.collab.parcours.proPre,
                    proPost: this.collab.parcours.proPost,
                    mobilite: this.collab.mobilite,
                    formations2ans: this.collab.projet.formations2ans,
                    formationsSouhait: this.collab.projet.formationsSouhait,
                    commentaire: this.collab.commentaire,
                    projet: this.collab.projet.souhait,
                    formations6ans: this.collab.bilan.formations6ans,
                    bilanPlus: this.collab.bilan.autresCommentaire,
                    entretien1: this.collab.bilan.entretien_1,
                    entretien2: this.collab.bilan.entretien_2,
                    save: save
                }
            }).then(
                response => {
                    console.log(response.data);
                    this.$router.push('/');
                }
            )
        },
        compareD: function (a, b) {
            if (a.annee < b.annee)
                return -1;
            if (a.annee > b.annee)
                return 1;
            return 0;
        },
        sortedDipl: function () {
            return this.collab.parcours.etudes.sort(this.compareD);
        }
    },
    mounted() {
        if (Object.keys(this.$store.state.collab) && Object.keys(this.$store.state.collab).length == 0)
            this.$router.push('/');
        else
            this.collab = this.$store.state.collab;
    },
}
</script>

<style scoped>
.help {
    font-style: italic;
    font-size: 14px
}

.contenButtons {
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 0 25px 25px 20px;
}

body {
    margin: 0;
}

.sub-content {
    margin-bottom: 60px;
}

.fade2-enter-active,
.fade2-leave-active {
    transition: all .3s ease;
}

.fade2-enter,
.fade2-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: all .3s ease;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}

.fade-enter {
    max-height: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.fade-enter-to {
    max-height: 80px;
}

.content {
    padding: 0 25px 0 20px;
}

.supp {
    font-weight: 900;
    font-size: 20px;
    color: rgb(255, 0, 0);
}

.add {
    float: right
}

.supp:hover {
    cursor: pointer;
}

.title-num-orange {
    color: #ff9015;
}

#head {
    background-color: #000644;
}

#head #content {
    width: 60%;
    min-width: 750px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 2fr;
    min-height: 175px
}

#loader {
    margin: auto;
    margin-top: 30%;
    text-align: center;
}

#head #logo {
    text-align: center;
    padding: 10px;
    margin-top: auto;
    margin-bottom: auto;
}

#title {
    font-size: 16px;
    color: #ff9015;
    letter-spacing: 4px;
    margin-top: auto;
    margin-bottom: auto
}

#type {
    color: white;
    letter-spacing: 3px;
    font-size: 20px
}

#corps {
    width: 60%;
    min-width: 750px;
    margin: auto;
}

#recap {
    margin: auto;
    width: 90%;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 15px 10px;
    border: 2px solid #ff9015;
    text-align: left;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    border-radius: 10px;
    background-color: #f3f3ff;
    font-size: 13px;
}

#recap .sub-recap {
    padding: 15px 20px;
}

#recap h2 {
    text-align: center;
}

.projet {
    display: grid;
    padding: 5px;
    grid-template-columns: minmax(225px, 1fr) 5fr;
}

.projet .l {
    text-align: right;
    padding: 15px;
}

.projet .r {
    padding: 10px
}

.projet .r input {
    width: 100%;
    padding: 5px;
}

#parcours {
    margin: auto;
    width: 100%;
    margin-top: 20px;
    text-align: left;
}

#formations {
    margin: auto;
    margin-top: 50px;
    text-align: left;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
    margin-bottom: 23px;
}

td,
th {
    text-align: center;
    border: 1px solid #002169;
}

td {
    padding: 0
}

.resp {
    margin-top: 7px;
    margin-bottom: 15px
}

td input,
td select {
    padding: 7px;
    width: 90%;
    height: 100%;

    border: none;
    background-color: transparent;
}

td input:focus,
td select:focus {
    outline: none !important;
}

td:focus-within {
    -webkit-box-shadow: inset 0px 0px 0px 3px #ff9015;
    -moz-box-shadow: inset 0px 0px 0px 3px #ff9015;
    box-shadow: inset 0px 0px 0px 3px #ff9015;
}

th {
    padding: 5px;
    background-color: #002169;
    color: white;
    border: 1px solid #646872;
}

button {
    font-family: "PT Sans", Helvetica, Arial, sans-serif;
    background-color: #ff9015;
    border: 2px solid #ff9015;
    color: #002169;
    min-width: 100px;
    padding: 7px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
}

button:hover {
    background-color: #002169;
    color: white;
    border: 2px solid #ff9015;
}

textarea {
    font-family: "PT Sans", Helvetica, Arial, sans-serif;
    resize: none;
    height: 200px;
    width: 100%;
    padding: 5px;
}

.empt {
    padding: 22px 20px;
    border: 1px solid transparent;
    border-radius: .25rem;
    border-color: #b6d4fe;
    background-color: #cfe2ff;
    margin-bottom: 20px;
    margin-top: 15px;
}

.inpu:focus {
    border-radius: 5px;
}

.inpu:focus {
    -webkit-box-shadow: inset 0px 0px 0px 2px #ff9015;
    -moz-box-shadow: inset 0px 0px 0px 2px #ff9015;
    box-shadow: inset 0px 0px 0px 2px #ff9015;
    outline: none !important;
    border-color: #ff9015;
}

@media screen and (max-width: 800px) {
    #head #content {
        text-align: center;
        width: 90%;
        min-width: 600px;
        grid-template-columns: 1fr;
    }

    #title {
        font-size: 12px;
        letter-spacing: 2px;
    }

    #head #logo {
        padding: 0px;
    }

    #corps {
        width: 90%;
        min-width: 600px;
        margin: auto;
    }

    .projet {
        display: grid;
        grid-template-columns: 1fr;
        padding: 0;
        padding-top: 20px;
    }

    .projet .l {
        text-align: left;
        padding: 0;
    }
}

@media screen and (max-width: 600px) {
    #head #content {
        text-align: center;
        width: 100%;
        min-width: 320px;
        grid-template-columns: 1fr;
    }

    #corps {
        width: 95%;
        min-width: 320px;
        margin: auto;
    }
}
</style>
